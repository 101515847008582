.jewelry-card-link {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    background: #FFF;
}

.jewelry-image img {
    width: 100%;
    height: 200px;
    object-fit: contain;
}

.jewelry-card-link {
    text-decoration: none;
}

.jewelry-details {
    /*display: flex;
    flex-direction: column;
    gap: 5px;*/
    padding: 10px;
    background: #FFF;
}

    .jewelry-details > p {
        color: #333;
        font-family: "Montserrat", sans-serif;
        display: flex;
        flex-direction: row;
    }

.lock-icon {
    margin-left: 5px;
}

.jewelry-title {
    height: 38px;
}

.jewelry-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.jewelry-details p.source-name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

#jewelry-notification-error {
    padding: 10px;
}

@media (max-width: 425px) {
    .jewelry-image img {
        height: unset;
    }
}