.navbar {
    background-color: #002D62;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-links-container {
    display: flex;
    justify-content: center;
    flex-grow: 1;
}

.navbar-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

    .navbar-links li {
        position: relative;
        margin: 0 10px;
    }

        .navbar-links li:not(:last-child)::after {
            content: '|';
            color: white;
            margin-left: 10px;
        }

    .navbar-links a {
        color: white;
        text-decoration: none;
        font-size: 18px;
    }

        .navbar-links a:hover {
            text-decoration: underline;
        }

.logout-button {
    padding: 0.5rem 1rem;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 1rem;
    align-content: end;
    margin-right: 15px;
}

    .logout-button:hover {
        background-color: #c82333;
    }