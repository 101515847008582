.active-filters {
    width: 100%;
    height: fit-content;
    padding: 20px;
}

.additional-container {
    padding: 20px;
    display: flex;
}

.lock-image {
    width: 25px;
    height: 25px;
    position: relative;
    top: 7px;
}

.left-panel {
    width: 100%;
    max-width: 370px;
    margin-right: 40px;
}

.jewelries-grid {
    display: grid;
    gap: 20px;
    align-items: stretch;
    width: 100%;
}

.sort-dropdown {
    position: relative;
    display: block;
    padding-left: 15px;
    padding-top: 10px;
    margin-bottom: 20px;
}

    .sort-dropdown select {
        padding: 8px 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
        appearance: none;
        background-color: white;
        font-size: 1rem;
        cursor: pointer;
        color: black;
        width: 100%;
    }

    .sort-dropdown:after {
        content: '\25BC';
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        pointer-events: none;
        color: #333;
    }

    .sort-dropdown:hover select {
        border-color: #999;
    }

.search-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-bottom: 20px;
}

.search-button {
    padding: 10px;
    border: none;
    background-color: white;
    border-radius: 0 10px 10px 0;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

    .search-button .search-icon {
        width: 20px;
        height: 20px;
    }

.item-filter {
    margin: 20px 0;
}

    .item-filter label {
        display: block;
        margin-bottom: 5px;
        cursor: pointer;
        background-color: white;
        color: black;
    }

    .item-filter input[type="checkbox"] {
        margin-right: 10px;
    }

    .item-filter .show-all {
        display: inline-block;
        color: #000;
        text-decoration: underline;
        cursor: pointer;
        margin-top: 10px;
    }

@media (min-width: 1281px) {
    .jewelries-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .jewelries-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .left-panel {
        max-width: 250px;
        margin-right: 20px;
    }

    .search-bar input {
        width: 100%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .jewelries-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .left-panel {
        max-width: 210px;
        margin-right: 20px;
    }

    .search-bar input {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .jewelries-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .left-panel {
        display: none;
    }
}

@media (max-width: 375px) {
    .jewelries-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}
