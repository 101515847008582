.auctions-data-container {
    min-height: 617px;
    max-height: 617px;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
    border-collapse: collapse;
}

.auctions-data-table {
    width: 100%;
    table-layout: auto;
}

    .auctions-data-table thead th {
        padding: 8px;
        border: 1px solid #ddd;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
    }

    .auctions-data-table tbody td {
        padding: 5px;
        border: 1px solid #ddd;
        box-sizing: border-box;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

.name-cell {
    text-align: left;
    padding: 8px;
    vertical-align: middle;
    padding-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dark-header {
    background-color: #fafafa;
}

.ActionButtonsContainer {
    display: flex;
    justify-content: center;
    gap: 8px;
}

.ActionButton {
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 5px;
}

.DeleteButton, .PublishButton {
    background-color: #2196F3;
}

.DeleteButton {
    background-color: #f44336;
}

    .DeleteButton:hover {
        background-color: #e57373;
    }

.PublishButton {
    background-color: #4CAF50;
}

    .PublishButton:hover {
        background-color: #81C784;
    }

@media (max-width: 600px) {
    .ActionButton {
        padding: 5px;
        font-size: 6px;
        min-width: 20px;
    }

    .ActionButtonsContainer {
        flex-wrap: nowrap;
    }
}

.two-line-cell {
    max-height: 2.8em;
    line-height: 1.4em;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin-left: 10px;
}

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .2s;
    border-radius: 20px;
}

    .slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        transition: .2s;
        border-radius: 50%;
    }

input:checked + .slider {
    background-color: #2196F3;
}

    input:checked + .slider:before {
        transform: translateX(20px);
    }

.EditAuctionButton, .SaveAuctionButton {
    background-color: #2196F3;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
}

.RecoverButton {
    background-color: darkorange;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
}

.UrlButton {
    width: 27px;
    height: 27px;
    margin-top: 2px;
    padding-left: 4px;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}