.container {
    background-color: #f8f9f9;
    display: flex;
    align-items: center;
}

.main-container {
    display: inline-block;
    background-color: #f8f9f9;
}

.column-container {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
}

span {
    color: #1561cb;
}

.input {
    background-color: #ffffff;
    color: #2c3142;
    border: 1px solid #ccc;
}

.column-desc {
    display: flex;
    width: 4vw;
    justify-content: flex-start;
}

.column-desc-value {
    align-self: flex-end;
    width: 3vw;
}

.MuiOutlinedInput-root {
    color: black;
}

.MuiInputLabel-outlined {
    color: black;
}

.column-desc-2 {
    width: 20vw;
    height: 10px;
    margin-top: 20px;
}

    .column-desc:nth-child(2) .info-field,
    .column-desc:nth-child(3) .info-field,
    .column-desc:nth-child(4) .info-field {
        align-content: end;
    }

.info-field {
    font-size: 14px;
    color: black;
}

.column-value {
    width: 8vw;
    height: 2vh;
    padding-right: 5px;
    color: #1561cb;
}

.column-value-2 {
    width: 15vw;
    height: 2vh;
    padding: 5px;
    padding-left: 50px;
    font-size: 1em;
    color: #1561cb;
}

.column-value > span {
    margin: 0;
}

.row > * {
    padding-right: 10px;
}

.column1 {
    width: 25vw;
}

.column2 {
    width: 75vw;
}

.column-container-info {
    height: 90vh;
    margin: 0;
    padding: 15px;
    width: 23.2vw;
}

.column-container-info-2 {
    display: flex;
    flex-direction: column;
    height: 90vh;
    margin: 0;
    padding: 15px;
    width: 70vw;
}

.column-desc-2,
.column-value-2 {
    margin-bottom: 10px;
}