.edit-auction-dialog {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px #ccc;
    background-color: white;
}

.edit-auction-row {
    margin-bottom: 10px;
}

    .edit-auction-row label {
        display: inline-block;
        width: 100px;
    }

    .edit-auction-row input {
        width: 80%;
        padding: 5px;
    }

.modal-edit-dialog {
    background: #fff;
    padding: 10px;
    padding-top: 0px;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.edit-auction-img-preview {
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    padding: 5px;
}

.edit-auciton-right-column {
    flex: 2;
    padding-left: 20px;
}

.edit-auciton-left-column {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-edit-dialog a {
    text-decoration: none;
    color: deepskyblue;
}

    .modal-edit-dialog a:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .modal-edit-dialog a:active {
        color: deepskyblue;
    }