.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1050;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-dialog {
	background: #fff;
	padding: 10px;
	padding-top: 0px;
	border-radius: 4px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	width: 30vw;
	max-height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.modal-header,
.modal-footer {
	display: flex;
}

	.modal-header h3 {
		margin: 0;
		font-size: 16px;
	}

	.modal-footer {
		justify-content: center;
	}

	.modal-content p {
		margin: 0;
		font-size: 14px;
		line-height: 1.4;
		overflow: hidden;
		text-overflow: ellipsis;
	}

.modal-footer {
	margin-top: 10px;
}

	.modal-footer button {
		padding: 5px 15px;
		font-size: 14px;
	}