.photo-switcher {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

    .photo-switcher img {
        width: 12vw;
        height: auto;
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
        height: 20vh;
    }

.button-wrapper {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.photo-switcher button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}