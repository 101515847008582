.add-types-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px;
    z-index: 1050;
    background: white;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    border-radius: 8px;
    overflow: hidden;
    padding: 20px;
}

body.modal-open .add-types-dialog::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    z-index: -1;
}

.close-button {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    background: red;
    font-size: 24px;
    cursor: pointer;
    border-radius: 15px;
    color: white;
    width: 30px;
    z-index: 1;
}