.pagination-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    background-color: #f9f9f9;
}

    .pagination-controls button {
        padding: 5px 10px;
        margin: 0 5px;
        cursor: pointer;
        border: 1px solid #ccc;
        background-color: #fff;
    }

        .pagination-controls button:disabled {
            cursor: default;
            opacity: 0.5;
        }

    .pagination-controls select {
        margin-left: 5px;
    }

    .pagination-controls span {
        margin-left: 10px;
    }

    .pagination-controls span {
        margin-right: 5px;
    }