.list-view {
    height: 75vh;
    width: 98%;
    border: 1px solid #ddd;
    padding: 10px;
}

.search-bar {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.search-input {
    outline: none;
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
    margin-right: 10px;
    transition: border-color 0.3s;
}

    .search-input.input-warning::placeholder {
        color: red;
    }

.add-button {
    width: 50px;
    height: 21px;
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 0 4px 4px 0;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

    .add-button:hover {
        background-color: #0056b3;
    }

.input-warning {
    border-color: red;
}

@keyframes blink-border {
    0%, 100% {
        border-color: red;
    }

    50% {
        border-color: inherit;
    }
}

.search-input.blink-border::placeholder {
    animation: blink-placeholder 1s step-start infinite;
}

.blink-border {
    animation: blink-border 1s step-start infinite;
}

.search-input:focus {
    border-color: blue;
}


.delete-button {
    margin-right: 10px;
    font-weight: bold;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    padding: 0 10px;
}

.list-item {
    padding: 5px 10px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

    .list-item:hover {
        background-color: #f5f5f5;
    }

.list {
    outline: none;
}

.list-item-name {
    flex-grow: 1;
    margin-right: auto;
}

.color-legend {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10px;
}

.color-text {
    margin-left: 5px;
    font-weight: bold;
}