.dropdown {
    position: relative;
    display: inline-block;
}

.dropbtn {
    background-color: #f9f9f9;
    color: black;
    padding: 6px 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    cursor: pointer;
    outline: none;
}

    .dropbtn .arrow-down {
        border: solid black;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        margin-left: 5px;
        vertical-align: middle;
        font-weight: normal;
    }

.dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 50px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 4px;
}

    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
    }

        .dropdown-content a:hover {
            background-color: #e1e1e1;
        }

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content a:focus {
    outline: none;
}