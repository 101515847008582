.auction-container {
    font-family: 'Arial', sans-serif;
    max-width: 50%;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    min-width: 200px;
}

.auction-header h1 {
    font-size: 18px; /* Fixed font size */
    margin-bottom: 0.5em;
}

.auction-header p {
    font-size: 14px; /* Fixed font size */
    color: #555;
    text-align: center;
}

.auction-details {
    border-top: 1px solid #eee;
    padding-top: 15px;
}

.detail-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.detail-label {
    font-weight: bold;
}

.detail-value {
    color: #333;
}

.auction-actions {
    margin-top: 20px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.navigation-buttons {
    margin-top: 20px;
}

.action-button {
    display: inline-block;
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px; /* Fixed font size */
    color: white;
    background-color: #007bff;
    text-align: center;
}

    .action-button.prev-button {
        font-weight: bold;
        background-color: #C6011F;
    }

        .action-button.prev-button:hover {
            background-color: #F40009;
        }

    .action-button.next-button {
        font-weight: bold;
        background-color: #40a829;
    }
        .action-button.next-button:hover {
            background-color: #03C03C;
        }

    .action-button:hover {
        opacity: 0.9;
    }

    .action-button:active {
        opacity: 0.8;
    }

.open-link-button {
    background-color: transparent;
    border: none;
    color: #1561cb;
    cursor: pointer;
}
    /* Media queries for smaller screens */
    @media (max-width: 768px) {
        .auction-header h1 {
        font-size: 22px; /* Slightly smaller font size */
    }

    .auction-header p,
    .detail-label,
    .detail-value {
        font-size: 12px; /* Slightly smaller font size */
    }

    .action-button {
        padding: 8px 15px;
        font-size: 14px; /* Slightly smaller font size for buttons */
    }
}

@media (max-width: 480px) {
    .auction-container {
        padding: 15px; /* Smaller padding */
    }

    .auction-header h1 {
        font-size: 20px; /* Smaller font size */
    }

    .auction-header p,
    .detail-label,
    .detail-value {
        font-size: 11px; /* Smaller font size */
    }

    .action-button {
        font-size: 13px; /* Smaller font size for buttons */
    }
}