.parsing-auction-container {
    display: flex;
    height: 100vh;
    font-family: Arial, sans-serif;
}

.parsing-auction-form {
    flex: 1;
    padding: 20px;
    background-color: #f4f4f4;
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
}

.parsing-auction-header {
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.parsing-auction-field {
    margin-bottom: 15px;
}

.parsing-auction-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.parsing-auction-input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.parsing-auction-preview {
    flex: 2;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.parsing-auction-iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.parsing-auction-placeholder {
    color: #aaa;
    font-size: 16px;
    text-align: center;
}