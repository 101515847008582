.custom-select {
    border: none;
    border-radius: 4px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 10px;
    margin-left: 2px;
}

    .custom-select option {
        padding: 10px;
        background: #256aad;
    }

        .custom-select option:checked,
        .custom-select option:active {
            background: #2480fa;
            color: white;
        }

.date-null {
    color: red;
    font-weight: bold;
    margin-left: 10px;
}