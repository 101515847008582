.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.page-button {
    background-color: white;
    color: black;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
}

    .page-button.active {
        border: 2px solid black;
    }

.go-to-page {
    display: flex;
    align-items: center;
    width: 176px;
    height: 19px;
    color: black;
}

    .go-to-page > div:nth-child(1) {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        width: 49px;
    }

.goto-page-input {
    border: 1px solid #9E9E9E;
    width: 70px;
    height: 15px;
    margin-left: 5px;
    background-color: white;
    color: black;
}

    .goto-page-input:focus {
        border: 1px solid rgb(0, 0, 0);
    }

.go-to-page > div:nth-child(3) {
    margin-left: 5px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    width: 47px;
}

.goto-page-button {
    padding: 8px 16px;
    border: 1px solid #ccc;
    background-color: white;
    color: black;
    cursor: pointer;
}

.pagination-ellipsis {
    user-select: none;
    padding: 10px 16px;
}

.choose-page-button1 {
    position: relative;
    outline: none;
    border: none;
    background-color: transparent;
    height: 16px;
    cursor: pointer;
    rotate: 90deg;
    transition: transform 0.3s ease-out;
}

.choose-page-button2 {
    position: relative;
    outline: none;
    border: none;
    background-color: transparent;
    height: 16px;
    cursor: pointer;
    rotate: 270deg;
    transition: transform 0.3s ease-out;
}

    .choose-page-button1:hover path, .choose-page-button2:hover path {
        fill: #F2F2F2;
    }

@media (max-width: 1200px) {
    .page-button {
        font-size: 0.9rem;
    }

    .goto-page-button, .goto-page-input, .go-to-page > div:nth-child(1), .go-to-page > div:nth-child(3) {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .pagination {
        gap: 4px;
    }

    .page-button {
        font-size: 0.8rem;
        padding: 4px 8px;
    }

    .goto-page-button, .goto-page-input, .go-to-page > div:nth-child(1), .go-to-page > div:nth-child(3) {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .pagination {
        /*        flex-direction: column;*/
        align-items: center;
        gap: 5px;
    }

    .page-button {
        font-size: 0.75rem;
        padding: 3px 8px;
    }

    .goto-page-button, .goto-page-input, .go-to-page > div:nth-child(1), .go-to-page > div:nth-child(3) {
        font-size: 12px;
        padding: 3px 8px;
    }

    .goto-page-input {
        width: 50px;
    }
}
