.login-container {
    background-color: #333; /* Темный фон формы */
    padding: 40px;
    border-radius: 10px; /* Скругленные углы */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

    .login-container:hover {
        transform: scale(1.05);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }

.login-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
}

.form-group {
    margin-bottom: 20px;
}

    .form-group label {
        display: block;
        margin-bottom: 5px;
        color: #8d8d8d;
    }

    .form-group input {
        width: 100%;
        padding: 10px;
        background: #404040; /* Фон полей ввода */
        color: #fff; /* Цвет текста ввода */
        border: 1px solid #555; /* Граница полей ввода */
        border-radius: 5px;
        margin-bottom: 10px;
    }

        .form-group input::placeholder {
            color: #bbb; /* Цвет плейсхолдера */
        }

        .form-group input:focus {
            outline: none;
            border-color: #55a630; /* Цвет границы при фокусе */
        }

.login-button {
    width: 80%;
    padding: 10px 0;
    margin-left: 30px;
    background-color: #55a630; /* Темно-зеленый фон кнопки */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

    .login-button:hover {
        background-color: #467a25;
    }

.form-group input:focus::placeholder {
    transition: opacity 0.4s ease-in-out;
    opacity: 0;
}