.main-container {
    display: flex;
    background-color: #f8f9f9;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    justify-content: flex-start;
    border-top: 3px solid #ccc;
    border-bottom: 3px solid #ccc;
    overflow: hidden;
}

.jewelry-info-main {
    width: 100%;
    height: 95%;
}

.iframe {
    margin-left:10px;
    width: 40vw;
    height: 100%;
    border: none;
}

.auction-info-sidebar {
    width: 30vw;
}