.add-new-item-dialog {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    max-width: 600px;
    position: fixed;
}

.close-button {
    position: absolute;
    top: 5px;
    right: 45px;
    border: none;
    background: red;
    font-size: 24px;
    cursor: pointer;
    border-radius: 15px;
    color: white;
    width: 30px;
    z-index: 1;
}

.modal-content {
    background: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    width: 80%;
    max-width: 600px;
    overflow: hidden;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.modal-title {
    width: 100%;
    text-align: center;
    font-size: 1.1em;
    color: #00BFFF;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    margin-top: -15px;
    font-weight: bold;
}

.add-new-item-dialog .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: darkblue;
}

.list-dialog {
    margin-bottom: 50px;
}