.event-status-container {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 0;
}

.event-status-column,
.event-moderation-status-column {
    margin-right: 50px;
    padding: 0;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 5px;
}

label {
    display: flex;
    align-items: center;
    padding: 0;
}

input[type="checkbox"] {
    margin-right: 10px;
    padding: 0;
}

.event-status-h {
    padding: 0;
    margin: 0;
}