.SourceTable {
    font-family: Arial, sans-serif;
    border-collapse: collapse;
    padding: 10px;
}

    .SourceTable table {
        width: 100%;
        table-layout: fixed;
    }

    .SourceTable th, .SourceTable td {
        border: 1px solid #ddd;
        text-align: left;
        padding: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .SourceTable tr:hover {
        background-color: #f1f1f1;
    }

    .SourceTable a {
        color: #0645ad;
        text-decoration: none;
    }

        .SourceTable a:hover {
            text-decoration: underline;
        }

    .SourceTable th.id, .SourceTable td.id {
        width: 10%;
    }

    .SourceTable th.title, .SourceTable td.title {
        width: 50%;
    }

    .SourceTable td.title {
        color: black;
    }

        .SourceTable th.url, .SourceTable td.url {
            width: 40%;
        }

.SaveSourceButton {
    border: none;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 5px;
    flex: 1;
    min-width: 60px;
}

.EditSourceButton {
    border: none;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 5px;
    flex: 1;
    min-width: 60px;
}

@media (max-width: 600px) {
    .ActionButton {
        padding: 5px;
        min-width: 20px;
    }
}

.EditSourceButton {
    background-color: dodgerblue;
}

    .EditSourceButton:hover {
        background-color: deepskyblue;
    }

.SaveSourceButton {
    background-color: limegreen;
}

    .SaveSourceButton:hover {
        background-color: lightgreen;
    }

.SourceTable .actions {
    text-align: center;
}

.info-button {
    width: 20px;
    height: 20px;
}

.editInput {
    width: 100%;
    padding: 3px;
    margin: 4px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: black;
    background-color: white;
}

    .editInput:focus {
        border-color: dodgerblue;
        outline: none;
    }

.table-menu {
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9f9f9;
    box-shadow: 0 4px 12px 0 rgba(0,0,0,0.2);
}

.search-and-select-container {
    display: flex;
    gap: 1rem;
}

.select-source-type {
    min-width: 200px;
    width: 10%;
}

.select-status-type {
    min-width: 150px;
    width: 8%;
}

.search-field {
    font-family: 'Arial', sans-serif;
    width: 100%;
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 1px transparent;
    transition: box-shadow 0.3s ease-in-out;
    outline: none;
}

    .search-field::placeholder {
        color: #aaa;
    }

    .search-field:hover {
        box-shadow: 0px 0px 0px 2px rgba(33, 150, 243, 0.5);
    }

    .search-field:focus {
        border-color: #2196F3;
        box-shadow: 0px 0px 0px 2px rgba(33, 150, 243, 0.5);
    }