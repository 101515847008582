.jewelry-main {
    display: block;
    flex-direction: row;
    align-items: stretch;
    border-left: 3px solid #ccc;
    border-right: 3px solid #ccc;
}

.category-select {
    width: 290px;
    padding: 0;
    margin: 0;
    margin-right: 10px;
}

.detail-item {
    margin: 7px;
    text-align: right;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
}

    .detail-item textarea {
        width: 100%;
        height: 120px;
    }

    .detail-item input {
        width: 100%;
        box-sizing: border-box;
        flex: 1;
    }

.jewelry-detail-label {
    text-align: right;
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-weight: bold;
}

    .jewelry-detail-label input {
        width: 100%;
        box-sizing: border-box;
        flex: 1;
    }

.fix-input-container {
    width: 15vw;
}

.detail-label {
    font-weight: bold;
    min-width: 6%;
    white-space: nowrap;
    margin-right: 10px;
}

.detail-value {
    flex-grow: 1;
    margin-right: 10px;
    color: #333;
}

@media (max-width: 768px) {

    .detail-label,
    .detail-value {
        font-size: 12px;
    }

    .action-button {
        padding: 8px 15px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .auction-container {
        padding: 15px;
    }

    .detail-label,
    .detail-value {
        font-size: 11px;
    }

    .action-button {
        font-size: 10px;
    }
}

.add-button {
    min-width: 70px;
    color: #fff;
    background-color: blue;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    outline: none;
    font-size: 12px;
    height: 28px;
    vertical-align: middle;
    box-sizing: border-box;
    margin-right: 10px;
}

.update-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.fix-button {
    width: 100%;
    max-width: 200px;
    margin: 5px;
    min-width: 70px;
    color: #fff;
    background-color: dodgerblue;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    outline: none;
    font-size: 12px;
    height: 28px;
    vertical-align: middle;
    box-sizing: border-box;
}

.delete-item-button {
    background-color: mediumvioletred;
}

.approve-item-button {
    background-color: green;
}

.adjustable-text-size {
    font-size: min(12.9px, 17px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 10px;
    color: #333;
    flex-grow: 1;
}

@media (max-width: 768px) {
    .adjustable-text-size {
        font-size: min(12.9px, 17px);
    }
}

@media (max-width: 480px) {
    .adjustable-text-size {
        font-size: min(12.9px, 17px);
    }
}

.suggestion-span {
    margin-left: 10px;
    padding: 5px 10px;
    font-size: 14px;
    color: #007bff;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

    .suggestion-span:hover {
        background-color: #e0e0e0;
        border-color: #007bff;
    }
